.viasat-logo-spinner-container {
	display: inline-block;
}

.viasat-logo-spinner-container-inner {
	height: 50px;
	aspect-ratio: 1.225;
	overflow: hidden;
}

.viasat-logo-spinner-mask {
	mask: url(images/viasat-animated-logo-mask.svg);
	mask-repeat: no-repeat;
	width: 100%;
	height: 100%;
}

.viasat-logo-spinner-animated-background {
	animation: 3s linear infinite viasatLogoSpinnerContinuous;
	background: linear-gradient(to right, #009FE3, #BED733, #202E39, #009FE3, #BED733, #202E39, #009FE3, #BED733, #202E39, #009FE3);
	background-size: 200%;
	width: 200%;
	height: 200%;
	transform: rotate(-16.5deg);
	position: relative;
	top: -50%;
	left: -50%;
}

@keyframes viasatLogoSpinnerContinuous {
	0% {
		background-position: 100%;
	}

	100% {
		background-position: -100%;
	}
}
