div.tag-list {
	margin-left: 3px;
	margin-bottom: 2px;
	display: inline-block;

	span {
		display: inline-block;
		padding: 0px 5px;
		border-radius: 4px;
		margin-left: 3px;
		background-color: #E0E0E0;
	}
}
