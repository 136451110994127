.selec-table table {
	user-select: none;
	width: 100%;

	tr {
		td, th {
			padding: 0px 4px;
		}

		th {
			background-color: #000000;
			border: solid 1px #000000 !important;
			color: #ffffff;
		}

		td {
			cursor: pointer;
		}

		&.no-hover td {
			cursor: default;
		}


		th:first-child,
		td:first-child {
			width: 38px;
		}

		th.number,
		td.number {
			text-align: right;
		}
	}

	tr:not(:first-child) {
		height: 24px;

		&:hover {
			background-color: #d4ffff;
		}

		&.no-hover:hover {
			background-color: #ffffff;
		}

		&.selected {
			background-color: #8affff;
		}
	}

	tr.no-files {
		cursor: default;

		&:hover {
			background-color: transparent;
		}
	}
}

.selec-table {
	overflow: auto;
}
