.nav-arrow {
	font-size: 1.1em !important;
	position: relative;
	top: 4px;
}

.header-cui-banner,
.header-cui-banner-mobile {
	clear: both;
	margin-left: 20px;
	font-weight: bold;
	color: #ff0000;
}

.header-cui-banner {
	font-size: larger;
}
