div.upload-status {
	min-width: 450px;
}

div.upload-status-list {
	margin: 1em;

	.active {
		font-weight: bold;
	}
}

.progress-bar-2 {
	width: 100px;
	height: 1em;
	display: inline-block;
	background-color: #e6e6e6;
	vertical-align: middle;
	margin-bottom: 2px;

	div {
		width: 0;
		background-color: #358000;
		height: 1em;
		transition: width 0.1s ease;
	}

	div.percent-text {
		width: 100%;
		vertical-align: middle;
		text-align: center;
		font-weight: normal;
		font-size: 0.85em;
		color: #ffffff;
		text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
	}

	div.error {
		background-color: #b90000;
		transition: none;
	}
}
