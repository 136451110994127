.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2000;
	text-align: center;
	padding: 25% 0;
}

.overlay-cover {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: black;
	opacity: 0;
	z-index: -1;
	transition: opacity 0.15s ease-in-out;
	-moz-transition: opacity 0.15s ease-in-out;
	-webkit-transition: opacity 0.15s ease-in-out;
}

.overlay-opacity {
	opacity: 0.5;
}
