div.dialog-header {
	padding: 0 20px;
	overflow: hidden;
}

div.dialog-footer {
	padding: 10px 20px;
	overflow: hidden;
}

div.dialog-inner {
	padding: 0 20px;

	& > div:first-child > :first-child {
		margin-top: 0;
	}
}
