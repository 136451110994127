div.combo-box {
	min-width: 200px;
}
/* HACK: Fix drop-down list display in fixed mode */
div.combo-box-single,
div.combo-box-multi {
	& > div:nth-child(3) {
		& > div:nth-child(1) {
			margin-top: 0;
			width: auto;
			border-radius: 0;

			& > div {
				padding: 0;

				& > div {
					padding: 2px 6px;
				}
			}
		}
	}
}
/* HACK: Add a placeholder to the user group user combo-box */
div.combo-box-user-group-user-with-placholder {
	width: 20em;

	& > div:nth-child(1) {
		& > div:nth-child(1) {
			& > div:nth-child(1):after {
				content: "Choose a user to add...";
			}
		}
	}
}
