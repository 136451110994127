div.react-datepicker-wrapper {
	input {
		width: 8em;
	}

	button:hover {
		border: none;
	}
}

div.react-datepicker-popper {
	div button {
		border-radius: 0;

		&:hover {
			padding: 0;
			border-width: 7px;
			border-color: transparent;
		}

		&.react-datepicker__navigation--previous:hover {
			border-right-color: #b3b3b3;
		}

		&.react-datepicker__navigation--next:hover {
			border-left-color: #b3b3b3;
		}
	}
}

button.react-datepicker__close-icon {
	&:hover {
		padding: 0px 6px 0px 0px;
	}

	&:active {
		padding: 0px 6px 0px 0px;
	}
}
