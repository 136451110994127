@keyframes glitch {
	90% {
		transform: none;
	}

	91% {
		transform: skew(180deg);
	}

	92% {
		transform: none;
	}
}

.glitchy {
	animation: glitch cubic-bezier(.5,-0.25,.75,1) 5s infinite;
}

.glitchy2 {
	animation: glitch cubic-bezier(.5,-0.25,.75,1) 9s infinite;
}

.glitchy3 {
	animation: glitch cubic-bezier(.5,-0.25,.75,1) 7s infinite;
}

.prevent-select {
	user-select: none;
}
