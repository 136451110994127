/* Viasat style fixes and adjustments */

html {
	font-size: inherit;
}

input[type="radio"]:checked,
input[type="radio"]:not(:checked),
input[type="checkbox"] {
	margin: 0;
	position: relative;
	left: 0;
	top: 2px;
}

.show {
	opacity: 1 !important;
}

button,
.input-file {
	border: none;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #26a1a1;
	padding: 8px 29px;
	background-color: transparent;
	border-radius: 25px;
	cursor: pointer;
	border: 1px solid #26a1a1;

	&:hover:enabled {
		padding: 7px 28px;
		border: 2px solid #008085;
		color: #008085;
	}

	&:active:enabled {
		padding: 7px 28px;
		border: 2px solid #006366;
		color: #006366;
	}

	&.primary:enabled {
		padding: 7px 28px;
		background-color: #26a1a1;
		color: #ffffff;
		border-width: 1px;

		&:hover:enabled {
			background-color: #008085;
		}

		&:active:enabled {
			background: #006366;
		}
	}
}

button[disabled] {
	background-color: #dddddd;
}

button.MuiButtonBase-root:hover {
	color: inherit;
	padding: 12px;
	border: 0;
}

h3 {
	margin-top: 20px;
	margin-bottom: 10px;
}

.fw-vp {
	max-width: none;
	width: unset;

	.hdr-brand {
		padding: 0;
	}
}

label {
	font-weight: normal;
	margin-right: 1.5em;
}

div.entry-table-container {
	margin: 10px 0;
	overflow: hidden;
	width: 100%;

	& > fieldset > div > table > tbody,
	& > div > table > tbody,
	& > table > tbody {
		width: 100%;

		& > tr > td {
			& > div {
				margin: 3px 0;
				overflow: hidden;
			}

			&:nth-child(1) {
				padding-right: 10px;
				font-weight: bold;
				min-width: 10em;
				width: 1px;
				white-space: nowrap;
				text-align: right;
			}

			&.info {
				white-space: normal;
			}
		}
	}
}

fieldset {
	min-width: 0;
}

legend {
	padding: 0 7px;
}

div.button-bar,
div.button-bar-split {
	padding: 10px 2px;

	& > * {
		margin: 0 10px;
	}

	& > a {
		line-height: 32px;
	}

	div.button-bar input[type=file] {
		display: none;
	}
}

div.button-bar-split {
	& > * {
		float: left;
	}
}

p.error-message,
span.error-message {
	color: #ff0000;
	font-weight: normal;
	text-align: left;
	display: block;
	white-space: normal;
}

.action-icon,
.action-icon * {
	cursor: pointer;
	float: right;
	margin-left: 10px;
}

.disabled,
.disabled * {
	cursor: default;
}

legend span {
	vertical-align: middle;
}

.ReactModal__Overlay {
	z-index: 1090;
}

p a,
a {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.hdr-navbar .nav > li > a.utility-nav-logout {
	text-transform: none;
}

select {
	padding: .25em 0;
}

input {
	padding: 2px .25em;
	border: 1px solid #A9A9A9;

	&[type=text],
	&[type=password] {
		width: 20em;
	}

	&.email-input {
		width: 25em;
	}

	&.long-input {
		width: 35em;
	}

	&.short-input {
		width: 10em;
	}

	&.tiny-input {
		width: 4em;
	}
}

textarea {
	padding: 2px .25em;
	border: 1px solid #AAAAAA !important;
	width: 35em;
}

.is-dirty,
div.combo-box.is-dirty:nth-child(1) > div:nth-child(1) > div:nth-child(1),
div.combo-box.is-dirty:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
	background-color: #e0fffd;
}

div.entry-table-inner-container {
	background-color: #ffffff;

	tr > td:nth-child(1) {
		vertical-align: top;
	}
}

.profile-table {
	tr:nth-child(odd) {
		background-color: #f2f2f2;
	}
}

.file-icon {
	transform: scale(.75);
	height: 24px;
}

.react-contextmenu {
	user-select: none;
	z-index: 1001;
}

i.arrow {
	border: solid #32424E;
	border-width: 0 1px 1px 0;
	display: inline-block;
	padding: 2px;
	top: -2px;
	position: relative;

	&.right {
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
	}

	&.left {
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
	}

	&.up {
		transform: rotate(-135deg);
		-webkit-transform: rotate(-135deg);
	}

	&.down {
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
	}
}

table.profile-table {
	width: auto;

	tr {
		th, td {
			border: 1px solid #C0C0C0;
		}
	}
}

.entity-profile-user-list-admin tr td {
	cursor: default !important;
}

.list-table-container {
	margin: 10px 0;
	overflow: hidden;
	width: 100%;
}

div.role-select-container {
	width: 500px;
}

div.fieldset-container {
	margin: 10px 0;
}

div.user-entity-profile-edit-modal {
	/* These values make the ComboBox display correctly */
	overflow: visible;
}

div.MuiPaper-root {
	overflow: auto;
}

.clickable {
	cursor: pointer;
}

div.main div.app {
	margin: 10px 20px 40px 20px;
}

// Header/footer styles - start
.navbar-nav > li > .dropdown-menu {
	cursor: pointer;
}

.navbar-toggle {
	cursor: pointer;

	&:hover {
		background-color: #F2F5F8 !important;
	}
}

ul.nav li {
	display: block;
	float: left;
}

@media (max-width: 768px) {
	.navbar-collapse {
		width: 10%;
		min-width: 250px;
		right: 0;
		background-color: #ffffff;
	}

	.navbar-collapse.in {
		height: auto !important;
		min-height: 200px;
	}
}

.hdr .avatar-container a[aria-expanded="false"] .nav-arrow:after {
	top: 0;
}

@media (min-width: 769px) {
	.hdr-navbar .nav a[aria-expanded="false"] .nav-arrow:after {
		top: 25px;
	}

	.navbar-nav li.open,
	.navbar-nav li.active {
		margin-top: -2px;
	}

	.hdr-navbar .nav a .nav-arrow:after {
		top: 22px;
	}
}

.hdr-navbar .navbar-nav {
	text-align: left;
}

@media (max-width: 768px) {
	.hdr-navbar .nav a[aria-expanded="false"] .nav-arrow:after {
		top: -4px;
	}

	.hdr-navbar .navbar-nav {
		margin: 0;
		width: 100%;
	}

	.navbar-collapse {
		padding: 0 0 20px 0;
	}

	.hdr-navbar .classic .dropdown-menu {
		width: 100%;
		border-bottom: 1px solid #a9adb0;
	}
}

.dropdown-menu {
	min-width: 0; /* fix menu width highlight */
	position: absolute;
}

.hdr-navbar .classic li {
	display: block; /* fix menu links */
	* {
		padding: 0 20px;
		line-height: 38px;
		color: #465967;
		font-size: 14px;
		text-decoration: none;
	}
}

.dropdown.show {
	display: inline-block !important;
}

.hdr .btn {
	top: 1px;
}

.nav .avatar-container a.avatar-icon {
	text-transform: none;
}

.hdr-navbar .nav > li > a {
	line-height: 56px;
}

.app-title {
	font-size: 22px;
	margin-left: 25px;
	top: 10px;
	position: relative;
	font-weight: bold;
}

.single-ftr .ftr-lnk-logo {
	border-right: none;
}

.single-ftr .ftr-links li {
	color: #c3cdd5;
}

.ftr-container a {
	color: #00A2FF;
}
// Header/footer styles - end
