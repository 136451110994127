div.login-form table tr td:nth-child(1) {
	vertical-align: inherit;
}

.login-status-popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-200px) translateY(-100px);
	background: #ffffff;
	border: solid 2px #000000;
	width: 400px;
	padding: 25px 15px 15px 15px;
}
