.file-drop-zone {
	user-select: none;
	cursor: pointer;
	clear: both;
	border: #CCCCCC dashed 4px;
	padding: 20px;
	width: 100%;
	top: 20px;
	position: relative;

	&:hover {
		border-color: #26a1a1;
	}

	&.file-hover {
		border-color: #358000;
	}

	p {
		margin: 0;
	}
}
