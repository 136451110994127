.file-section-enter {
	max-height: 0;
	opacity: 0;
}

.file-section-enter.file-section-enter-active {
	max-height: 400px;
	opacity: 1;
	transition: max-height 500ms linear,opacity 500ms linear;
}

.file-section-exit {
	max-height: 400px;
	opacity: 1;
}

.file-section-exit.file-section-exit-active {
	max-height: 0;
	opacity: 0;
	transition: max-height 300ms linear, opacity 300ms linear;
}


.file-section-row-enter div {
	max-height: 0;
	opacity: 0;
}

.file-section-row-enter.file-section-row-enter-active div {
	max-height: 40px;
	margin: 3px 0;
	opacity: 1;
	transition: max-height 300ms linear, opacity 300ms linear, margin 300ms linear;
}

.file-section-row-exit div {
	max-height: 40px;
	opacity: 1;
}

.file-section-row-exit.file-section-row-exit-active div {
	max-height: 0;
	margin: 0 0;
	opacity: 0;
	transition: max-height 300ms linear, opacity 300ms linear, margin 300ms linear;
}
