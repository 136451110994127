.RegExTesterContent {
	margin: 1em 0;

	th, td {
		padding: 1px 4px;
		border: 1px solid #000000;
	}

	.pass {
		color: #ffffff;
		background-color: #008e00;
	}

	.fail {
		color: #ffffff;
		background-color: #d00000;
	}
}
